/* line 13, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
.m-fourofour {
	margin-top: 60px;
	margin-bottom: 60px;
}

/* line 19, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
.fourofour__title {
	color: rgb(var(--color-global-primary));
}

/* line 26, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
.fourofour__subtitle {
	font-size: 1.5em;
}

@media only screen and (max-width: 47.999em) {
	/* line 26, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
	.fourofour__subtitle {
		font-size: 1.05em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 26, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
	.fourofour__subtitle {
		font-size: 1.2495em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 26, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
	.fourofour__subtitle {
		font-size: 1.5em;
	}
}

/* line 38, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
.fourofour__title, .fourofour__subtitle {
	text-align: center;
}

/* line 44, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
.fourofour__subheading {
	margin: 40px 0 20px;
	color: rgb(var(--color-global-helper));
}

/* line 49, scss/80-themes/Saunders/70-modules/fourofour/module.scss */
.fourofour__heading,
.fourofour__heading + .fourofour__subheading {
	margin-top: 20px;
}

/*# sourceMappingURL=../../../../../true */